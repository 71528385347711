import header_main_img from "../assets/images/header_main_img.jpg";
import about_main_img from "../assets/images/about_main_img.jpg";
import form_main_img from "../assets/images/form_main_img.jpg";
import professionals_main_img from "../assets/images/professionals_main_img.jpg";
import video_cover_img from "../assets/images/video_cover_img.jpg";
import customer_img_1 from "../assets/images/customer_img_1.jpg";
import customer_img_2 from "../assets/images/customer_img_2.jpg";
import customer_img_3 from "../assets/images/customer_img_3.jpg";
import customer_img_4 from "../assets/images/customer_img_4.jpg";
import customer_img_5 from "../assets/images/customer_img_5.jpg";
import customer_img_6 from "../assets/images/customer_img_6.jpg";

import portfolio_img_1 from "../assets/images/portfolio_img_1.jpg";
import portfolio_img_2 from "../assets/images/portfolio_img_2.jpg";
import portfolio_img_3 from "../assets/images/portfolio_img_3.jpg";

import proserve1 from "../assets/images/1.png";
import proserve2 from "../assets/images/2.png";
import proserve3 from "../assets/images/3.png";
import proserve4 from "../assets/images/4.png";
import proserve5 from "../assets/images/5.png";
import proserve6 from "../assets/images/6.png";
import proserve7 from "../assets/images/7.png";
import proserve8 from "../assets/images/8.png";
import proserve9 from "../assets/images/9.png";

import pro1 from "../assets/images/a1.png";
import pro2 from "../assets/images/a2.png";
import bga1 from "../assets/images/bg1.png";
import bga2 from "../assets/images/bg2.png";

import main1 from "../assets/images/m1.jpg";
import main2 from "../assets/images/m2.jpg";
import main3 from "../assets/images/m3.jpg";

const images = {header_main_img, about_main_img, form_main_img, professionals_main_img, video_cover_img, customer_img_1, customer_img_2, customer_img_3, customer_img_4, customer_img_5, customer_img_6, portfolio_img_1, portfolio_img_2, portfolio_img_3, proserve1, proserve2,proserve3, proserve4, proserve5, proserve6, proserve7, proserve8, proserve9, pro1, pro2, bga1, bga2, main1, main2,main3};

export default images;