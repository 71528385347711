import React from 'react'
import "./Financialcafe.css"
import images from '../../constants/images';

const Financialcafe = () => {
  return (
    <section className='financialcafe section-p' id='financialcafe'>
      <div className='container'>
        <div className='grid financialcafe-content'>
          <div className='image-left'>
          <img src = {images.bga2} alt = "" />
          </div>
          <div className='column-box'>
            <div className='row-box'>
        
            <h3 className='brand-name text-black'>The Masters Financial Cafe</h3>
            <p className='text parah fs-15 text-brandt'>A pioneering social enterprise offering an array of relevant and impactful financial services in digital application geared towards fostering economic sustainability and equality in the marketplace.</p>

            </div>

            <div className='finanservices grid row-box finan-content'>
            <div className='program-circle'>
              
                <h3>Savings</h3>
              </div>
              <div className='program-circle'>
                <h3>Loan</h3>
              </div>
              <div className='program-circle'>
                <h3>Enterprise Development</h3>
              </div>
            </div>
          </div>
          <div className=''>
            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Financialcafe
