import React from 'react';
import "./ProServe.css";
import NavBar from '../NavBar/NavBar';
import Financialcafe from './Financialcafe';
import Fintech from './Fintech';
import Footer from '../Footer/Footer';
import images from '../../constants/images';

const ProServe = () => {
  return (
    <>
      <NavBar />
      <section className='proserve section-p' id='proserve'>
        <div className='container'>
          <div className="grid proserve-content">
            <div className='column-box'>
              <div className='row-box'>
                <div className='brand-name text-black'>The Masters Inc.</div>
                <p className='text parah fs-15 text-brandt'>The MASTER'S Inc – is a marketplace association organized to bring together stakeholders of the marketplace - employees, skilled contract workers, management professionals, and business owners to join hands and implement holistic programs and services that foster transformation and shared prosperity.</p>
              </div>
              <div className='programs grid row-box program-content'>
                <div className='program-circle'>
                  <h3>Transformation</h3>
                </div>
                <div className='program-circle'>
                  <h3>Family Strengthening</h3>
                </div>
                <div className='program-circle'>
                  <h3>Work Force Development</h3>
                  </div>
              </div>
            </div>
            <div className='imageside'>
              <img src={images.bga1} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Financialcafe />
      <Fintech />
      <Footer />
    </>
  )
}

export default ProServe;
