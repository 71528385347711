import React from 'react'
import "./CoreValues.css"; 
import images from '../../constants/images';

const CoreValues = () => {
  return (
    <section className='corevalues section-p  bg-white ' id = "corevalues">
    <div className='container'>
        <div className='corevalues-content grid'>
            <div className='content-left'>
                <div className='section-t text-center'>
                    <h3 className='text-black'>Core Values</h3>
                    {/* <p className='parah text text-brandt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde voluptates deserunt, molestiae soluta cumque eum.</p> */}
                </div>

                <div className='item-list grid text-white'>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Master & Mission Centric</h3>
                                <p className='text'>The underlying existence of this  organization  is that it is joining God in His activity in the Marketplace.  As such,  the directions, programs and thrusts will always be anchored on fulfilling the great commission in the marketplace and providing programs towards advancing transformation and shared prosperity.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Adaptable</h3>
                                <p className='text'>As we revolve in a fast paced and changing environment, we will remain adaptable to the changes, as we see fit.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Social Impact Oriented</h3>
                                <p className='text'>As an organization,  we will implement programs and services that are transformation oriented,   and foster inclusion and economic sustainability.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Technology Driven</h3>
                                <p className='text'>This organization will leverage and capitalize on technology to be able to deliver services and programs effectively and efficiently.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Engaging</h3>
                                <p className='text'>We engage regularly with stakeholders to maintain close connection and be informed of their perspectives and feedbacks, while also regularly sharing to them about the organization's directions , programs and services.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Relevant & Innovative</h3>
                                <p className='text'>Through stakeholder engagements,  we will develop and implement programs, services and activities that are innovative and responsive to their needs.</p>
                        </div>
                    </div>
                    <div className='item flex' > 
                        <div className='item-text'>
                            <h3 className='item-title fs-10 text-black'>Stakeholder Empowerment</h3>
                                <p className='text'>In keeping with our mission,  our programs and services are geared towards empowering and unleashing the talents,  competencies,  gifts, and skills of  our stakeholders.  </p>
                        </div>
                    </div>
                </div>   
            </div>

            <div className='content-right'>
                <img src = {images.professionals_main_img} alt = "" />
            </div>
        </div>
    </div>
</section>
  )
}

export default CoreValues
