import React from 'react';
import "./Footer.css";
import {Link} from 'react-router-dom';
import {FaTwitter, FaSkype, FaVimeoV} from "react-icons/fa";



const Footer = () => {

    let date = new Date();

  return (
    <footer className='footer bg-black flex flex-center' id = "footer">
        <div className='container flex flex-center w-100'>
            <div className='grid footer-content text-center'>
                <span className='text'>
                    <h1 className='text-brand'>THE MASTERS</h1>
                    <div>
                    <ul className='navbar-social flex'>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaTwitter /></Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaSkype /></Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaVimeoV /></Link>
                            </li>
                        </ul>
                    </div> 
                </span>
                <span className='text company'>
                    <h3>Company</h3>
                    <p>About</p>
                    <p>Programs and Features</p>
                    <p>Partnership</p>
                    <p>Whats New</p>
                </span>
                <p className='text'>
                    <div>
                    E-mail : themastersplan777@gmail.com
                    </div>
                    <div>
                    Mobile : +639173021766
                    </div>
                </p>
                
                <span className='text'>&copy; {date.getFullYear().toString()} DevSandboxOnline</span>
            </div>
        </div>
    </footer> 
  )
}

export default Footer
