import React from 'react'
import "./Services2.css"


const Services2 = () => {
  return (
    
<section className="services2 section-p  bg-white-a" id="2">
        <div className="container">
            <div className="services2-content text-center flex flex-column">
                <header className="text-service ">What's New?</header>
                    <p className="para-service text-brandt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum quibusdam aut tempore exercitationem<br></br>accusantium deserunt sequi illo ut qui distinctio. </p>
                    <a href="/" className="btn header-btn btn-gray">
                <span>Find out now!</span>
            </a>
            </div>
        </div>
        

        
</section>
  
  )
}

export default Services2
