import React from 'react'
import "./Whatsnew.css"
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
const Whatsnew = () => {
  return (
    <div>
      <NavBar></NavBar>
      <section className='section-p'>
        <div className="container">
        <h1>What's new?</h1>
        </div>
        
      </section>
      <Footer></Footer>
      </div>
  )
}

export default Whatsnew
