import React from 'react'

const Team = () => {
  return (
    <div>
  
    </div>
  )
}

export default Team
