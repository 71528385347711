import React from 'react';
import './App.css';
import Header from './components/Header/Header'; 
import Services from './components/Services/Services';
import Services2 from './components/Services/Services2';
import Services3 from './components/Services/Services3'
import "./assets/js/script";
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Services></Services>
      <Services2></Services2>
      <Services3></Services3>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
