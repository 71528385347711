import React from 'react'
import CoreValues from './CoreValues'
import Team from './Team'
import NavBar from '../NavBar/NavBar'
import "./About.css";
import images from '../../constants/images';
import Footer from '../Footer/Footer';

const About = () => {
  return (
    <>
    
        <NavBar />
        <section className='about section-p  bg-white-a' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {images.about_main_img} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h3 className='text-black brand-name'>About Us</h3>
                    </div>
                    <p className = "text text-brandt">We are an open marketplace hybrid social enterprise partner providing myriad technology-driven,  responsive programs and services anchored on transformation and shared prosperity over-arching goals. ​​</p> 
                </div>
            </div>
        </div>
        </section>


        <CoreValues />
        <Team /> 
     
     
    <Footer></Footer>
    </>
  )
}

export default About
