import React from 'react'
import "./Contactus.css"
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'


const Contactus = () => {
  return (
    <div>
    <NavBar></NavBar>
    <section className='section-p'>
      <div className="container">
      <h1>Just Contact Us!</h1>
      </div>
      
    </section>
    <Footer></Footer>
    </div>
)
}

export default Contactus
