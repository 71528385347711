import React from 'react'; 
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import About from './components/About/About';
import ProServe from './components/Program and Services/ProServe';
import Partnership from './components/Partnerships/Partnership';
import Whatsnew from './components/Whatsnew/Whatsnew';
import Contactus from './components/Contact Us/Contactus';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
      <Route path = "/" element = {<App />}></Route>
      <Route path = "/about" element = {<About />}></Route>
      <Route path='/proserve' element = {<ProServe/>}></Route>
      <Route path='/partnerships' element = {<Partnership/>}></Route>
      <Route path='/whatsnew' element = {<Whatsnew/>}></Route>
      <Route path='/contactus' element = {<Contactus/>}></Route>

      </Routes> 
   
    
    </BrowserRouter>
    
  </React.StrictMode>
);

