import React from 'react'
import "./Fintech.css"
import images from '../../constants/images';

const Fintech = () => {
  return (
    <section className='fintech section-p' id='fintech'>
      <div className='container'>
        <div className='text-center flex flex-column fintech-content'>

         
          <div className='row-box flex-start'>
        
          <div className='brand-name text-black'>The Masters Fintech Cafe.</div>
          <div className='text parah text-p text-brandt'>In the context where most of the stakeholders in the marketplace are offering in the digital space, the MASTERS Fintech Café is a game changer hybrid neo-social enterprise joint venture that gives expression to shared prosperity and financial inclusion..</div>

          </div>
          <div className='column-box'>

          <div className='fintech grid row-box fintech-content'>
            <div className='program-circle'>
              
                <h3>Loan Products</h3>
              </div>
              <div className='program-circle'>
                <h3>Digital Sales Platforms</h3>
              </div>
              <div className='program-circle'>
                <h3>Payout and Remittance</h3>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  )
}

export default Fintech
