import React from 'react'
import "./Partnership.css"
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'

const Partnership = () => {
  return (
    <div>
      <NavBar />
      <section className='section-p'>
        <div className="container">
        <h1>Partnerships</h1>
        </div>
        
      </section>
      <Footer></Footer>
    </div>
  )
}

export default Partnership
