import React from 'react'
import "./Services3.css"
import images from '../../constants/images';

const Services3 = () => {
  return (
   <section className='services3 section-p bg-md-white'>
    <div className='container'>
        <div className='services3-content grid'>
            <div className='content-left'>
              <div className='section-s3 text-left'>
                <h3 className='text-black'>TRANSFORMATION AND SHARED PROSPERITY</h3>
                <p className='text textser parah text-brandt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis quae esse molestias nulla optio, nostrum laborum reprehenderit dolorem blanditiis.

                </p>
                <a href="/about" className="btn header-btn btn-gray">
                <span>About Us</span>
            </a>
              </div>
             
            </div>

            <div className='content-right'>
                <img src = {images.professionals_main_img} alt = "" />
            </div>
        </div>

    </div>
   </section>
  )
}

export default Services3
