import React from 'react';
import './Services.css';


const Services = () => {
  return (
    <section className='services section-p bg-md-white' id='services'>
      <div className='container'>
        <div className='services-content'>
          <h3 className="text-service">Our Marketplace Target</h3>
          <div className='flex item-list content-center'>
            <div className='item imagea'>
              <h1 className='title'>Maritime Professionals</h1>
            </div>
            <div className='item image2'>
              <h1 className='title'>Manpower Industries</h1>
            </div>
            <div className='item image3'>
              <h1 className='title'>BPO Professionals</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
